import WidgetStatus from '../../components/widget/Status'
import $format from '../../utils/formatter'

export default {
    "entity": "protocol_has_account",
    id: ['protocol_id','account_id'],
    "title": "protocol accounts",
    "upsertTitle": "protocol account",
    sortBy: 'protocol_profile.name',
    "headers": [ 
        {
            "key": "account_id",
            size:6,
            "type": "autocomplete",
            subtitle: item => $format('account', item, 'subtitle'),
            title: item => $format('account', item, 'title'),
            render: item => item?.account?.name + ' ' + item?.account?.surname + " (" + item?.account?.email  + ")",
            filter: (search, item) => ({ 'account.system_role': ['External','Employee', 'Employee Admin'] ,accountItem:[{'account.name':`${search}%`}, {'account.surname':`${search}%`}, {'account.email': `%${search}%`}]}),
            readonly: item => !!item.insert_at,
            validators: ['required'],
        },

        {
            "key": "profile_id",
            label: 'Profile Name',
            size:6,
            id:"id",
            reference:'protocol_profile',
            "type": "autocomplete",
            table: {
                filter: search => ({ 'protocol_profile.name': `%${search}%`}), 
            },
            filter: (search, item) => ({ protocol_id: item.protocol_id , 'protocol_profile.name':`%${search}%`}),
            validators: ['required'],
            sortBy: 'name'
        },
       
        {
            "key": "status",
            "name" : "status",
            "type": "list",
            table: {
                width: 100
            },
            list: [ 'Active', 'Disabled'],
            validators: ['required'],
        },
        
    ],
    "actions": [
        "upsert",
        {
            action: 'upsert',
            name: 'organization',
            icon: 'mdi-card-account-details',
            text: 'Organizations',
            color: 'success'
        },
        "remove"
    ]
}