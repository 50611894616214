export default defineNuxtPlugin({
  name: 'dialog',
  setup: (nuxtApp) => {
    const { $once, $emit } = nuxtApp
    function confirm (msg, title) {
      $emit('confirmDialog', { msg, title })
      return new Promise((resolve, reject) => {
        $once('confirmDialog:result', resolve)
      })
    }
    
    function notify (msg, status) {
      $emit('notify', { msg: msg?.response?._data?.message ?? String(msg), status })
    }

    function prompt (msg, status, name, title) {
      $emit('promptDialog', { msg, status: 'warning', name, title })
      return new Promise((resolve, _reject) => {
        $once('promptDialog:result', resolve)
      })
    }  

    return { provide: { confirm, notify, prompt } }

  }
})
