import { default as config_accountIQanvybM8RMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/accounts/config_account.js?macro=true";
import { default as indexNjgN2secUjMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/accounts/index.vue?macro=true";
import { default as control_45centerSWlXcYSVaKMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/admin/control-center.vue?macro=true";
import { default as confirm_45forgot_45passHOUw3M51UBMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/confirm-forgot-pass.vue?macro=true";
import { default as config_logajqTPOuuoeMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dashboard/config_log.js?macro=true";
import { default as indexlveIDjSwrGMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dashboard/index.vue?macro=true";
import { default as config_data_dictionaryRYRzH4myoVMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dd/config_data_dictionary.js?macro=true";
import { default as indextIt34TfKXTMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dd/index.vue?macro=true";
import { default as config_accountjWJr4xWpKMMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example/config_account.js?macro=true";
import { default as indexIRFguic2lWMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example/index.vue?macro=true";
import { default as config_account0aaANkAvWGMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example2/config_account.js?macro=true";
import { default as indexs1Ls2qRDo1Meta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example2/index.vue?macro=true";
import { default as _91_91node_id_93_93wB6ZF0dXnAMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/explorer/[[node_id]].vue?macro=true";
import { default as dataaqM7tp7vzSMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/explorer/data.js?macro=true";
import { default as config_treatment0yjHgOoJywMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/ip/config_treatment.js?macro=true";
import { default as indexi3Eoq4DtQHMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/ip/index.vue?macro=true";
import { default as loginrRceWQR03nMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/login.vue?macro=true";
import { default as index1zallmalHWMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/metric/index.vue?macro=true";
import { default as indexsDKxJEXKpzMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/milestone_setup/index.vue?macro=true";
import { default as config_organizationg1dAJn6te8Meta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/organizations/config_organization.js?macro=true";
import { default as indexAPzc3gB0uVMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/organizations/index.vue?macro=true";
import { default as configKgPuFvuM87Meta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/pending/config.js?macro=true";
import { default as dataxo2AFF2KRzMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/pending/data.js?macro=true";
import { default as index1Rr0Mb8aecMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/pending/index.vue?macro=true";
import { default as indexH9dsWeaIXqMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/permission/index.vue?macro=true";
import { default as config_personnelF1DEhH4iHgMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/personnel/config_personnel.js?macro=true";
import { default as indexnuhJOOCDh7Meta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/personnel/index.vue?macro=true";
import { default as indexnkttFg14vMMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/profile/index.vue?macro=true";
import { default as config_protocol_has_account_has_organizationp1xGGn5htLMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account_has_organization/config_protocol_has_account_has_organization.js?macro=true";
import { default as indexGFjROUJVUyMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account_has_organization/index.vue?macro=true";
import { default as config_protocol_has_accountpWMXZ1YGE9Meta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account/config_protocol_has_account.js?macro=true";
import { default as indexuXbYEmjJKjMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account/index.vue?macro=true";
import { default as config_protocol_has_organizationw9hsQuWL6uMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_organization/config_protocol_has_organization.js?macro=true";
import { default as indexxEHo7HXkHDMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_organization/index.vue?macro=true";
import { default as config_protocol_has_personnel1dn3UByAPEMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_personnel/config_protocol_has_personnel.js?macro=true";
import { default as indexFbMbem5T1DMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_personnel/index.vue?macro=true";
import { default as indexPgb2En7kzeMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocols/index.vue?macro=true";
import { default as datajF6fLHKL2kMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/report/data.js?macro=true";
import { default as indexDbdVUoW2dvMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/report/index.vue?macro=true";
import { default as reset_45passwordlB0nJjPp6XMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/reset-password.vue?macro=true";
import { default as config_siteFMFlblfERwMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/site/config_site.js?macro=true";
import { default as indexpE4wJDuCSXMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/site/index.vue?macro=true";
import { default as config_study_roledRv5Jh6grMMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/study_role/config_study_role.js?macro=true";
import { default as indextY3oagLzdbMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/study_role/index.vue?macro=true";
import { default as config_task7vMAGMHm9RMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/task/config_task.js?macro=true";
import { default as index7hn0VnEArMMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/task/index.vue?macro=true";
import { default as indexf8rpdC42dnMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/testflex/index.vue?macro=true";
import { default as indexerCxAEDRreMeta } from "/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/tree_permission/index.vue?macro=true";
export default [
  {
    name: config_accountIQanvybM8RMeta?.name ?? "accounts-config_account",
    path: config_accountIQanvybM8RMeta?.path ?? "/accounts/config_account",
    meta: config_accountIQanvybM8RMeta || {},
    alias: config_accountIQanvybM8RMeta?.alias || [],
    redirect: config_accountIQanvybM8RMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/accounts/config_account.js").then(m => m.default || m)
  },
  {
    name: indexNjgN2secUjMeta?.name ?? "accounts",
    path: indexNjgN2secUjMeta?.path ?? "/accounts",
    meta: indexNjgN2secUjMeta || {},
    alias: indexNjgN2secUjMeta?.alias || [],
    redirect: indexNjgN2secUjMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: control_45centerSWlXcYSVaKMeta?.name ?? "admin-control-center",
    path: control_45centerSWlXcYSVaKMeta?.path ?? "/admin/control-center",
    meta: control_45centerSWlXcYSVaKMeta || {},
    alias: control_45centerSWlXcYSVaKMeta?.alias || [],
    redirect: control_45centerSWlXcYSVaKMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/admin/control-center.vue").then(m => m.default || m)
  },
  {
    name: confirm_45forgot_45passHOUw3M51UBMeta?.name ?? "confirm-forgot-pass",
    path: confirm_45forgot_45passHOUw3M51UBMeta?.path ?? "/confirm-forgot-pass",
    meta: confirm_45forgot_45passHOUw3M51UBMeta || {},
    alias: confirm_45forgot_45passHOUw3M51UBMeta?.alias || [],
    redirect: confirm_45forgot_45passHOUw3M51UBMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/confirm-forgot-pass.vue").then(m => m.default || m)
  },
  {
    name: config_logajqTPOuuoeMeta?.name ?? "dashboard-config_log",
    path: config_logajqTPOuuoeMeta?.path ?? "/dashboard/config_log",
    meta: config_logajqTPOuuoeMeta || {},
    alias: config_logajqTPOuuoeMeta?.alias || [],
    redirect: config_logajqTPOuuoeMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dashboard/config_log.js").then(m => m.default || m)
  },
  {
    name: indexlveIDjSwrGMeta?.name ?? "dashboard",
    path: indexlveIDjSwrGMeta?.path ?? "/dashboard",
    meta: indexlveIDjSwrGMeta || {},
    alias: indexlveIDjSwrGMeta?.alias || [],
    redirect: indexlveIDjSwrGMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: config_data_dictionaryRYRzH4myoVMeta?.name ?? "dd-config_data_dictionary",
    path: config_data_dictionaryRYRzH4myoVMeta?.path ?? "/dd/config_data_dictionary",
    meta: config_data_dictionaryRYRzH4myoVMeta || {},
    alias: config_data_dictionaryRYRzH4myoVMeta?.alias || [],
    redirect: config_data_dictionaryRYRzH4myoVMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dd/config_data_dictionary.js").then(m => m.default || m)
  },
  {
    name: indextIt34TfKXTMeta?.name ?? "dd",
    path: indextIt34TfKXTMeta?.path ?? "/dd",
    meta: indextIt34TfKXTMeta || {},
    alias: indextIt34TfKXTMeta?.alias || [],
    redirect: indextIt34TfKXTMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/dd/index.vue").then(m => m.default || m)
  },
  {
    name: config_accountjWJr4xWpKMMeta?.name ?? "example-config_account",
    path: config_accountjWJr4xWpKMMeta?.path ?? "/example/config_account",
    meta: config_accountjWJr4xWpKMMeta || {},
    alias: config_accountjWJr4xWpKMMeta?.alias || [],
    redirect: config_accountjWJr4xWpKMMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example/config_account.js").then(m => m.default || m)
  },
  {
    name: indexIRFguic2lWMeta?.name ?? "example",
    path: indexIRFguic2lWMeta?.path ?? "/example",
    meta: indexIRFguic2lWMeta || {},
    alias: indexIRFguic2lWMeta?.alias || [],
    redirect: indexIRFguic2lWMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example/index.vue").then(m => m.default || m)
  },
  {
    name: config_account0aaANkAvWGMeta?.name ?? "example2-config_account",
    path: config_account0aaANkAvWGMeta?.path ?? "/example2/config_account",
    meta: config_account0aaANkAvWGMeta || {},
    alias: config_account0aaANkAvWGMeta?.alias || [],
    redirect: config_account0aaANkAvWGMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example2/config_account.js").then(m => m.default || m)
  },
  {
    name: indexs1Ls2qRDo1Meta?.name ?? "example2",
    path: indexs1Ls2qRDo1Meta?.path ?? "/example2",
    meta: indexs1Ls2qRDo1Meta || {},
    alias: indexs1Ls2qRDo1Meta?.alias || [],
    redirect: indexs1Ls2qRDo1Meta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/example2/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91node_id_93_93wB6ZF0dXnAMeta?.name ?? "explorer-node_id",
    path: _91_91node_id_93_93wB6ZF0dXnAMeta?.path ?? "/explorer/:node_id?",
    meta: _91_91node_id_93_93wB6ZF0dXnAMeta || {},
    alias: _91_91node_id_93_93wB6ZF0dXnAMeta?.alias || [],
    redirect: _91_91node_id_93_93wB6ZF0dXnAMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/explorer/[[node_id]].vue").then(m => m.default || m)
  },
  {
    name: dataaqM7tp7vzSMeta?.name ?? "explorer-data",
    path: dataaqM7tp7vzSMeta?.path ?? "/explorer/data",
    meta: dataaqM7tp7vzSMeta || {},
    alias: dataaqM7tp7vzSMeta?.alias || [],
    redirect: dataaqM7tp7vzSMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/explorer/data.js").then(m => m.default || m)
  },
  {
    name: config_treatment0yjHgOoJywMeta?.name ?? "ip-config_treatment",
    path: config_treatment0yjHgOoJywMeta?.path ?? "/ip/config_treatment",
    meta: config_treatment0yjHgOoJywMeta || {},
    alias: config_treatment0yjHgOoJywMeta?.alias || [],
    redirect: config_treatment0yjHgOoJywMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/ip/config_treatment.js").then(m => m.default || m)
  },
  {
    name: indexi3Eoq4DtQHMeta?.name ?? "ip",
    path: indexi3Eoq4DtQHMeta?.path ?? "/ip",
    meta: indexi3Eoq4DtQHMeta || {},
    alias: indexi3Eoq4DtQHMeta?.alias || [],
    redirect: indexi3Eoq4DtQHMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/ip/index.vue").then(m => m.default || m)
  },
  {
    name: loginrRceWQR03nMeta?.name ?? "login",
    path: loginrRceWQR03nMeta?.path ?? "/login",
    meta: loginrRceWQR03nMeta || {},
    alias: loginrRceWQR03nMeta?.alias || [],
    redirect: loginrRceWQR03nMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: index1zallmalHWMeta?.name ?? "metric",
    path: index1zallmalHWMeta?.path ?? "/metric",
    meta: index1zallmalHWMeta || {},
    alias: index1zallmalHWMeta?.alias || [],
    redirect: index1zallmalHWMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/metric/index.vue").then(m => m.default || m)
  },
  {
    name: indexsDKxJEXKpzMeta?.name ?? "milestone_setup",
    path: indexsDKxJEXKpzMeta?.path ?? "/milestone_setup",
    meta: indexsDKxJEXKpzMeta || {},
    alias: indexsDKxJEXKpzMeta?.alias || [],
    redirect: indexsDKxJEXKpzMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/milestone_setup/index.vue").then(m => m.default || m)
  },
  {
    name: config_organizationg1dAJn6te8Meta?.name ?? "organizations-config_organization",
    path: config_organizationg1dAJn6te8Meta?.path ?? "/organizations/config_organization",
    meta: config_organizationg1dAJn6te8Meta || {},
    alias: config_organizationg1dAJn6te8Meta?.alias || [],
    redirect: config_organizationg1dAJn6te8Meta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/organizations/config_organization.js").then(m => m.default || m)
  },
  {
    name: indexAPzc3gB0uVMeta?.name ?? "organizations",
    path: indexAPzc3gB0uVMeta?.path ?? "/organizations",
    meta: indexAPzc3gB0uVMeta || {},
    alias: indexAPzc3gB0uVMeta?.alias || [],
    redirect: indexAPzc3gB0uVMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/organizations/index.vue").then(m => m.default || m)
  },
  {
    name: configKgPuFvuM87Meta?.name ?? "pending-config",
    path: configKgPuFvuM87Meta?.path ?? "/pending/config",
    meta: configKgPuFvuM87Meta || {},
    alias: configKgPuFvuM87Meta?.alias || [],
    redirect: configKgPuFvuM87Meta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/pending/config.js").then(m => m.default || m)
  },
  {
    name: dataxo2AFF2KRzMeta?.name ?? "pending-data",
    path: dataxo2AFF2KRzMeta?.path ?? "/pending/data",
    meta: dataxo2AFF2KRzMeta || {},
    alias: dataxo2AFF2KRzMeta?.alias || [],
    redirect: dataxo2AFF2KRzMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/pending/data.js").then(m => m.default || m)
  },
  {
    name: index1Rr0Mb8aecMeta?.name ?? "pending",
    path: index1Rr0Mb8aecMeta?.path ?? "/pending",
    meta: index1Rr0Mb8aecMeta || {},
    alias: index1Rr0Mb8aecMeta?.alias || [],
    redirect: index1Rr0Mb8aecMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/pending/index.vue").then(m => m.default || m)
  },
  {
    name: indexH9dsWeaIXqMeta?.name ?? "permission",
    path: indexH9dsWeaIXqMeta?.path ?? "/permission",
    meta: indexH9dsWeaIXqMeta || {},
    alias: indexH9dsWeaIXqMeta?.alias || [],
    redirect: indexH9dsWeaIXqMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/permission/index.vue").then(m => m.default || m)
  },
  {
    name: config_personnelF1DEhH4iHgMeta?.name ?? "personnel-config_personnel",
    path: config_personnelF1DEhH4iHgMeta?.path ?? "/personnel/config_personnel",
    meta: config_personnelF1DEhH4iHgMeta || {},
    alias: config_personnelF1DEhH4iHgMeta?.alias || [],
    redirect: config_personnelF1DEhH4iHgMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/personnel/config_personnel.js").then(m => m.default || m)
  },
  {
    name: indexnuhJOOCDh7Meta?.name ?? "personnel",
    path: indexnuhJOOCDh7Meta?.path ?? "/personnel",
    meta: indexnuhJOOCDh7Meta || {},
    alias: indexnuhJOOCDh7Meta?.alias || [],
    redirect: indexnuhJOOCDh7Meta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/personnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexnkttFg14vMMeta?.name ?? "profile",
    path: indexnkttFg14vMMeta?.path ?? "/profile",
    meta: indexnkttFg14vMMeta || {},
    alias: indexnkttFg14vMMeta?.alias || [],
    redirect: indexnkttFg14vMMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_account_has_organizationp1xGGn5htLMeta?.name ?? "protocol_has_account_has_organization-config_protocol_has_account_has_organization",
    path: config_protocol_has_account_has_organizationp1xGGn5htLMeta?.path ?? "/protocol_has_account_has_organization/config_protocol_has_account_has_organization",
    meta: config_protocol_has_account_has_organizationp1xGGn5htLMeta || {},
    alias: config_protocol_has_account_has_organizationp1xGGn5htLMeta?.alias || [],
    redirect: config_protocol_has_account_has_organizationp1xGGn5htLMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account_has_organization/config_protocol_has_account_has_organization.js").then(m => m.default || m)
  },
  {
    name: indexGFjROUJVUyMeta?.name ?? "protocol_has_account_has_organization",
    path: indexGFjROUJVUyMeta?.path ?? "/protocol_has_account_has_organization",
    meta: indexGFjROUJVUyMeta || {},
    alias: indexGFjROUJVUyMeta?.alias || [],
    redirect: indexGFjROUJVUyMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account_has_organization/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_accountpWMXZ1YGE9Meta?.name ?? "protocol_has_account-config_protocol_has_account",
    path: config_protocol_has_accountpWMXZ1YGE9Meta?.path ?? "/protocol_has_account/config_protocol_has_account",
    meta: config_protocol_has_accountpWMXZ1YGE9Meta || {},
    alias: config_protocol_has_accountpWMXZ1YGE9Meta?.alias || [],
    redirect: config_protocol_has_accountpWMXZ1YGE9Meta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account/config_protocol_has_account.js").then(m => m.default || m)
  },
  {
    name: indexuXbYEmjJKjMeta?.name ?? "protocol_has_account",
    path: indexuXbYEmjJKjMeta?.path ?? "/protocol_has_account",
    meta: indexuXbYEmjJKjMeta || {},
    alias: indexuXbYEmjJKjMeta?.alias || [],
    redirect: indexuXbYEmjJKjMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_account/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_organizationw9hsQuWL6uMeta?.name ?? "protocol_has_organization-config_protocol_has_organization",
    path: config_protocol_has_organizationw9hsQuWL6uMeta?.path ?? "/protocol_has_organization/config_protocol_has_organization",
    meta: config_protocol_has_organizationw9hsQuWL6uMeta || {},
    alias: config_protocol_has_organizationw9hsQuWL6uMeta?.alias || [],
    redirect: config_protocol_has_organizationw9hsQuWL6uMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_organization/config_protocol_has_organization.js").then(m => m.default || m)
  },
  {
    name: indexxEHo7HXkHDMeta?.name ?? "protocol_has_organization",
    path: indexxEHo7HXkHDMeta?.path ?? "/protocol_has_organization",
    meta: indexxEHo7HXkHDMeta || {},
    alias: indexxEHo7HXkHDMeta?.alias || [],
    redirect: indexxEHo7HXkHDMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_organization/index.vue").then(m => m.default || m)
  },
  {
    name: config_protocol_has_personnel1dn3UByAPEMeta?.name ?? "protocol_has_personnel-config_protocol_has_personnel",
    path: config_protocol_has_personnel1dn3UByAPEMeta?.path ?? "/protocol_has_personnel/config_protocol_has_personnel",
    meta: config_protocol_has_personnel1dn3UByAPEMeta || {},
    alias: config_protocol_has_personnel1dn3UByAPEMeta?.alias || [],
    redirect: config_protocol_has_personnel1dn3UByAPEMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_personnel/config_protocol_has_personnel.js").then(m => m.default || m)
  },
  {
    name: indexFbMbem5T1DMeta?.name ?? "protocol_has_personnel",
    path: indexFbMbem5T1DMeta?.path ?? "/protocol_has_personnel",
    meta: indexFbMbem5T1DMeta || {},
    alias: indexFbMbem5T1DMeta?.alias || [],
    redirect: indexFbMbem5T1DMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocol_has_personnel/index.vue").then(m => m.default || m)
  },
  {
    name: indexPgb2En7kzeMeta?.name ?? "protocols",
    path: indexPgb2En7kzeMeta?.path ?? "/protocols",
    meta: indexPgb2En7kzeMeta || {},
    alias: indexPgb2En7kzeMeta?.alias || [],
    redirect: indexPgb2En7kzeMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/protocols/index.vue").then(m => m.default || m)
  },
  {
    name: datajF6fLHKL2kMeta?.name ?? "report-data",
    path: datajF6fLHKL2kMeta?.path ?? "/report/data",
    meta: datajF6fLHKL2kMeta || {},
    alias: datajF6fLHKL2kMeta?.alias || [],
    redirect: datajF6fLHKL2kMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/report/data.js").then(m => m.default || m)
  },
  {
    name: indexDbdVUoW2dvMeta?.name ?? "report",
    path: indexDbdVUoW2dvMeta?.path ?? "/report",
    meta: indexDbdVUoW2dvMeta || {},
    alias: indexDbdVUoW2dvMeta?.alias || [],
    redirect: indexDbdVUoW2dvMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/report/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordlB0nJjPp6XMeta?.name ?? "reset-password",
    path: reset_45passwordlB0nJjPp6XMeta?.path ?? "/reset-password",
    meta: reset_45passwordlB0nJjPp6XMeta || {},
    alias: reset_45passwordlB0nJjPp6XMeta?.alias || [],
    redirect: reset_45passwordlB0nJjPp6XMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: config_siteFMFlblfERwMeta?.name ?? "site-config_site",
    path: config_siteFMFlblfERwMeta?.path ?? "/site/config_site",
    meta: config_siteFMFlblfERwMeta || {},
    alias: config_siteFMFlblfERwMeta?.alias || [],
    redirect: config_siteFMFlblfERwMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/site/config_site.js").then(m => m.default || m)
  },
  {
    name: indexpE4wJDuCSXMeta?.name ?? "site",
    path: indexpE4wJDuCSXMeta?.path ?? "/site",
    meta: indexpE4wJDuCSXMeta || {},
    alias: indexpE4wJDuCSXMeta?.alias || [],
    redirect: indexpE4wJDuCSXMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/site/index.vue").then(m => m.default || m)
  },
  {
    name: config_study_roledRv5Jh6grMMeta?.name ?? "study_role-config_study_role",
    path: config_study_roledRv5Jh6grMMeta?.path ?? "/study_role/config_study_role",
    meta: config_study_roledRv5Jh6grMMeta || {},
    alias: config_study_roledRv5Jh6grMMeta?.alias || [],
    redirect: config_study_roledRv5Jh6grMMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/study_role/config_study_role.js").then(m => m.default || m)
  },
  {
    name: indextY3oagLzdbMeta?.name ?? "study_role",
    path: indextY3oagLzdbMeta?.path ?? "/study_role",
    meta: indextY3oagLzdbMeta || {},
    alias: indextY3oagLzdbMeta?.alias || [],
    redirect: indextY3oagLzdbMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/study_role/index.vue").then(m => m.default || m)
  },
  {
    name: config_task7vMAGMHm9RMeta?.name ?? "task-config_task",
    path: config_task7vMAGMHm9RMeta?.path ?? "/task/config_task",
    meta: config_task7vMAGMHm9RMeta || {},
    alias: config_task7vMAGMHm9RMeta?.alias || [],
    redirect: config_task7vMAGMHm9RMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/task/config_task.js").then(m => m.default || m)
  },
  {
    name: index7hn0VnEArMMeta?.name ?? "task",
    path: index7hn0VnEArMMeta?.path ?? "/task",
    meta: index7hn0VnEArMMeta || {},
    alias: index7hn0VnEArMMeta?.alias || [],
    redirect: index7hn0VnEArMMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/task/index.vue").then(m => m.default || m)
  },
  {
    name: indexf8rpdC42dnMeta?.name ?? "testflex",
    path: indexf8rpdC42dnMeta?.path ?? "/testflex",
    meta: indexf8rpdC42dnMeta || {},
    alias: indexf8rpdC42dnMeta?.alias || [],
    redirect: indexf8rpdC42dnMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/testflex/index.vue").then(m => m.default || m)
  },
  {
    name: indexerCxAEDRreMeta?.name ?? "tree_permission",
    path: indexerCxAEDRreMeta?.path ?? "/tree_permission",
    meta: indexerCxAEDRreMeta || {},
    alias: indexerCxAEDRreMeta?.alias || [],
    redirect: indexerCxAEDRreMeta?.redirect,
    component: () => import("/Users/anto80/Sites/TRIALSH/eTMF/etmf/frontend/pages/tree_permission/index.vue").then(m => m.default || m)
  }
]