import WidgetRole from "../../components/widget/Role"
import WidgetStatus from "../../components/widget/Status"

export default {

    entity: 'account',
    title: 'Accounts',
    upsertTitle: 'Account',
    headers: [
        {
            key: 'name',
            render: item => `${item?.name ?? ''} ${item?.surname}`,
            validators: ['required'], // ci sono un po' di validatori di default (required, email)
            filter: search => ({ 'name':[
                { 'name': `%${search}%`},
                { 'surname': `%${search}%`},
              ]}),
            width: 150,
            table: {
                label: 'First and Last Name'
            },
            label: 'First name'
        },
        {
            key: 'surname',
            label: 'Last name',
            table: false,
            validators: ['required'],
        },        
        {
            width: 100,
            key: 'username',
            validators: ['required']
        },
        {
            key: 'email',
            validators: [ 'email']
        },
        {
            key: 'system_role',
            label: "System Role",
            type: 'list',
            list: ['Administrator', 'Employee Admin', 'Employee', 'External', 'TRIALSH Client'],
            validators: ['required'],
            table: {
                width: 150,
            }
        },
        {
            key: 'status',
            type: 'list',
            default: 'Active',
            list: ['Active', 'Disabled'],
            validators: ['required'],
            table: {
                width: 100,
                component: WidgetStatus
            }
        },
    ],

    // specifico le actions da fare su questa entita'
    // se sono stringhe (upsert o remove) c'e' un comportamento standard
    actions: [
        {
            name: 'emulateUser',
            icon: 'mdi-head-sync-outline',
            text: 'Emulate User',
            color: 'success',
            visible: (item) => {
                return item.system_role != 'Administrator' && item.system_role != 'TRIALSH Client'
            }
        },
        'upsert', 'remove'
    ]
}