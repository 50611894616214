import { DateTime } from 'luxon'

export default defineNuxtPlugin((nuxtApp) => {

    function formatDate(date) {
        if (!date) return ''
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return new Date(date).toLocaleDateString(undefined, options)
    }

    function formatDateTime(date) {
        if (!date) return ''
        const options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
        return new Date(date).toLocaleString(undefined, options)
    }
  
    function toRelative (date) {
        return DateTime.fromSQL(date).toRelative({ style: 'long' })
    }

    return { provide: { formatDate, formatDateTime, toRelative } }
  })
  