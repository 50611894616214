export default {
    "entity": "study_role",
    "headers": [
        {
            "key": "name",
            "title": "Name",
            "type": "text",
            "table": {
                "sortable": true,
                "filter": true
            },
            "validators": [
                "required"
            ]
        },
        {
            "key": "type",
            "title": "Type",
            "type": "list",
            "table": {
                "sortable": true,
                "filter": true
            },
            list: ['System', 'Site', 'Lab', 'Cro'],
            "validators": [
                "required",
            ]
        },
        // {
        //     "key": "status",
        //     "title": "Status",
        //     "type": "list",
        //     list: ['Active', 'Disabled'],
        //     "table": {
        //         "sortable": true,
        //         "filter": true
        //     },

        //     "validators": [
        //         "required"
        //     ]
        // },
    ],
    "actions": [
        // "upsert",
        // "remove"
    ]
}