import WidgetStatus from '../../components/widget/Status'
import $format from '../../utils/formatter'

export default {
    "entity": "protocol_has_account_has_organization",
    id: ['protocol_id','account_id', 'organization_id'],
    "title": "protocol account organization",
    "headers": [
        {
            "key": "protocol_id",
            size:6,
            "type": "autocomplete",
            "subtitle": "sponsor.name",
            readonly: item => !!item.insert_at,
            validators: ['required'],
        },
      
        {
            "key": "account_id",
            id:"account_id",
            size:6,
            reference:"protocol_has_account",
            "type": "autocomplete",
            deps: ['protocol_id'],
            subtitle: item => item.account.email,
            title: item => item?.account.name + ' ' + item?.account.surname,
            render: item => item?.account?.name + ' ' + item?.account?.surname + " (" + item?.account?.email  + ")",
            table: {
                filter: search => ({ accountItem:[{'account.name':`${search}%`}, {'account.surname':`${search}%`}, {'account.email':`%${search}%`}]})
            },
            filter: (search, item) => ({ protocol_id:item.protocol_id, accountItem:[{'account.name':`${search}%`}, {'account.surname':`${search}%`}, {'account.email':`%${search}%`}]}),
            readonly: item => !!item.insert_at,
            validators: ['required'],
        },
        {
            "key": "type",
            width: 100,
            id: 'organization.type',
            render: item => item.organization.type,
            "type": "list",
            list: ['CRO', 'Central Laboratory', 'Depot', 'Other', 'Site'],
            // visible: false,
            "filter": search => ({'organization.type' : `${search}%`})
        },
        {
            "key": "organization_id",
            reference:"protocol_has_organization",
            id:"organization_id",
            size:12,
            width: 300,
            deps: ['type', 'protocol_id'],
            "type": "autocomplete",
            filter: (search, item) => ({protocol_id: item.protocol_id, "organization.type": item?.type, 'name' : [{'organization.label': `%${search}%`}, {'organization.code': `%${search}%`}, {'organization.name': `%${search}%`}]}),
            table: {
                filter: (search, item) => ([{'organization.label': `%${search}%`}, {'organization.code': `%${search}%`}, {'organization.name': `%${search}%`}]),
            },
            title: item => $format('protocol_organization', item, 'title'),
            subtitle: item => $format('protocol_organization', item, 'subtitle'), // item => `${item?.code ?? ''} ${item.country.name} - ${item.type}`,
            readonly: item => !!item.insert_at || !item.type,
            render: item => item.organization.type == 'Site' ? item.organization.label : item.organization.name
        },
        
    ],
    "actions": [
        "upsert",
        "remove"
    ]
}