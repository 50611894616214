import { createVuetify } from "vuetify"
import colors from 'vuetify/lib/util/colors.mjs'
import '@/assets/main.scss'
import VueApexCharts from "vue3-apexcharts"

// import VCalendar from 'v-calendar'
// import 'v-calendar/style.css'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    ssr: false,
    theme: {
      themes: {
        light: {
          dark: false,
          colors: {
            primary: colors.amber.accent3,
            secondary: colors.amber.accent1,
            accent: colors.amber.lighten3,
            error: colors.deepOrange.accent4,
            info: colors.blue.base,
            success: colors.green.base,
            warning: colors.deepOrange.accent3,
          },
        },
      }
      // add color variations
      //   variations: {
      //     colors: ["primary", "secondary"],
      //     lighten: 3,
      //     darken: 3,
      //   },
    },
    defaults: {
      global: {
        elevation: 0,
        // rounded: 0,
      },
      VDialog: {
        maxWidth: '800',
      },
      VFileInput: {
        prependIcon: '',
        prependInnerIcon: 'mdi-attachment',
        density: 'compact'
      },
      VSelect: {
        density: 'compact'
      },
      VBtn: {
        elevation: 0,
        variant: 'flat',
        ripple: 0
      },
      VTextField: {
        autocomplete: 'off',
        density: 'compact',
        clearable: true
      },
      VComboBox: {
        autocomplete: 'off',
        density: 'compact',
        clearable: true
      },
      VAutocomplete :{
        VChip: {
          closable: true,
          closeIcon: "mdi-close-circle",
        },
        autocomplete: 'off',
        density: 'compact',
        clearable: true
      },
      VAlert: {
        border: 'start',
        type: 'warning',
        variant: 'tonal',
        rounded: 0
      },
      VSwitch: {
        density: 'compact',
        inset: true,
        color: 'indigo'
      },
      VSelect: { 
        density: 'compact'
      },
      VDataTableServer: {
        density: 'compact',
      },
      VDataTableRow: {
        density: 'compact'
      },
      VDataTableItem: {
        density: 'compact'
      }
    },
    // Add the custom iconset
    icons: {
      defaultSet: "custom",
      aliases,
      sets: {
        custom,
      },
    },
  });

  // app.vueApp.use(VCalendar)
  app.vueApp.use(vuetify)
  app.vueApp.use(VueApexCharts)
})
