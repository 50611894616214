
export default () => {
	const config = useRuntimeConfig()

	const authToken = useCookie('etmf_authToken', { sameSite: 'strict' })
	const emulatedUserId = useCookie('emulatedUserId', { sameSite: 'strict' })

	const authUser = useState("auth_user")

	const serviceConfig = useState("serviceConfig", () => false)
	const loginDisabled = computed(() => serviceConfig?.value?.maintenance?.length > 0 &&  serviceConfig?.value?.maintenance.filter(el => !el.allowLogin).length)

	const authAbility = computed(() => authUser?.value?.rules ?? [])
	const authPerProtocolAbility = computed(() => authUser?.value?.protocols ?? [])

	const authLoading = useState("auth_loading", () => false)
	const isAuthenticated = computed( () => !! authUser?.value?.account_id)

	function setUser (newUser) {
		authUser.value = newUser
		authToken.value = newUser?.token ?? null
		if (newUser) {
			localStorage.setItem("auth_user", JSON.stringify(newUser))
		} else {
			localStorage.removeItem("auth_user")
		}
	}

	async function getServiceConfig () {
		serviceConfig.value = await useAPI('config')
		const maintenance = serviceConfig?.value?.maintenance ?? []

        const mustLogout = maintenance.filter(el => !el.allowLogin).length
		if (mustLogout) {
			logout()
		}
	}

	async function login ({ username, password }) {
		return new Promise(async (resolve, reject) => {
			try {
				authLoading.value = true
				const data = await $fetch(`${config.public.baseURL}/auth/login`, {
					method: "POST",
					body: {
						username,
						password
					}
				})
				setUser(data)
				await getUser()
				resolve(true)
			} catch (error) {
				reject(error)
			} finally {
				authLoading.value = false
			}
		});
	}

	async function getUser (force = false) {

		if (authUser.value && !force) {
			return authUser.value
		}

		const headers = {}

		if (authToken.value) {
			headers['Authorization'] = `Bearer ${authToken.value}`
		}

		if (emulatedUserId.value) {
			headers['isEmulation'] = emulatedUserId.value
		}

		try {
			const data = await $fetch(`${config.public.baseURL}/auth/check`, { headers })
			setUser(data)
			return data
		} catch (e) {
			console.error(e)
		}

	}

	async function logout () {
		return new Promise(async (resolve, reject) => {
			try {
				if (authToken.value) {
					await useAPI('auth/logout', { method: 'DELETE' })
				}
				emulatedUserId.value = null
				setUser(null)
				await navigateTo("/login")
				resolve()
			} catch (error) {
				reject(error)
			}
		})
	}

	/** Emulation */
	function startEmulation (userId) {
		emulatedUserId.value = userId
		getUser(true)
	}

	function stopEmulation () {
		emulatedUserId.value = null
		getUser(true)
	}
	
	function forgotPass (body) {
		return $fetch(`${config.public.baseURL}/auth/forgotPass`, {
			method: 'post',
			body
		})
	}
	
	function confirmForgotPass (body) {
		return $fetch(`${config.public.baseURL}/auth/confirmForgotPass`, {
			method: 'POST',
			body
		})
	}
	
	function changePassword (body) {
		const headers = {}
		
		if (authToken.value) {
			headers['Authorization'] = `Bearer ${authToken.value}`
		}
		
		if (emulatedUserId.value) {
			headers['isEmulation'] = emulatedUserId.value
		}

		return $fetch(`${config.public.baseURL}/auth/changePass`, {
			method: 'POST',
			headers,
			body
		})
	}

// import { defineAbility } from '@casl/ability'
// import { abilitiesPlugin } from '@casl/vue'

    /**
    [ {
        "action": [ "read", "create", "update", "upsert", "check", "delete", "execute", "confirm", "remove", "emulateUser", "fill" ],
        "subject": [ "all" ]
    } ]
     */

    // const ability = defineAbility((can, cannot) => {
    //     console.error('sono dentro define ability!')
    //     cannot('do', 'anything')
    // })

    // const { authAbility } = useAuth()

    // ability.update(authAbility.value)
    // watch(authAbility, () => ability.update(authAbility.value))

    // nuxtApp.vueApp.use(abilitiesPlugin, ability, {
    //     useGlobalProperties: true
    // })

    function can(action, subject, object) {

		// check global abilities first
        let global_allowed = authAbility.value.find(ability => (ability.action.includes(action) || ability.action.includes('all')) &&
            (ability.subject.includes(subject) || ability.subject.includes('all')))

        // if (!global_allowed) {
		// 	console.warn(`[ETMF can warning] Action ${action} not allowed on ${subject} globally but continue to check for specific permission`)
        //     // return false
        // }
		
		// cannot use useFilters directly!
		const filters = useState("filters")

		// if (filters.value?.protocol.status !== 'Open') {
		// 	return false
		// }

		const protocol_id = object?.protocol_id ?? filters?.value?.protocol?.id

		// console.error(`[CAN] ${action} ${subject} (global: ${global_allowed}, protocol_id: ${protocol_id})`)

		if (!authPerProtocolAbility?.value[protocol_id]) {
			// console.error(`[ETMF can warning] per protocol ability not found`)
			return !!global_allowed
		}
		const allowed = authPerProtocolAbility.value[protocol_id]?.permission.find(ability => (ability.action === action) &&
			(ability.subject === subject))
		
		if (!allowed) {
			// console.error("[eTMF] not allowed", action, subject)
			return !!global_allowed
		}
		return true


    }

	return {
		login,
		logout,
		getUser,
		forgotPass,
		changePassword,
		confirmForgotPass,

		authUser,
		authToken,
		authLoading,
		authAbility,
		authPerProtocolAbility,
		
		isAuthenticated,

		// emulation
		startEmulation,
		stopEmulation,
		emulatedUserId,

		serviceConfig,
		getServiceConfig,
		loginDisabled,

		can
	};
};
