import upperFirst from 'lodash/upperFirst'
export default function format (entity, item, type = 'table') {
    switch (entity) {
        case 'organization':
            switch (type) {
                case 'table':
                case 'title':
                    return item?.label ?? item?.name
                case 'subtitle':
                    return `${item?.city ?? ''} ${item?.code ? '('+ item.code + ')' : ''} [${item?.type}]`
                case 'audit_trail':
                    return item?.code
                case 'search':

            }
            break
        case 'protocol_organization':
                switch (type) {
                    case 'table':
                    case 'audit_trail':
                    case 'title':
                        return item?.organization?.label ?? item?.organization?.name
                    case 'subtitle':
                        return `${item.organization?.city ?? ''} ${item?.organization?.code ? '('+ item.organization?.code + ')' : ''} [${item?.organization?.type}]`
                    case 'search':
    
                }
                break
        case 'personnel':
            switch (type) {
                case 'table':
                case 'title':
                case 'audit_trail':
                    return `${item?.name ?? ''} ${item?.surname ?? ''}`
                case 'subtitle':
                    return item.organization?.label ?? item.organization.name
                case 'organization':
                    return item.organization?.label
            }
            break
        case 'account':
            switch (type) {
                case 'table':
                case 'title':
                case 'audit_trail':
                    return (item?.name ?? '') + ' ' + (item?.surname ?? '')
                case 'subtitle':
                    return `${item?.email} [${item?.profile ?? item?.system_role}]`
            }
        case 'path_code':
            return 'CF' + item.replace(/\(.*?\)/g,'').split('.').map(i => i.length === 1 ? '0' + i : i).join('.')
        case 'country':
            return item?.name
        case 'file_status':
            const statusMap = {
                'to approve': 'To Approve',
                'expected': 'Expected',
                'in progress': 'In progress',
                'rejected': 'Rejected',
                // 'approved_qc_open': 'QC Open',
                // 'approved_qc_replied': 'QC Replied',
                // 'approved_qc_closed': 'Approved (QC Closed)',
                'approved': 'Approved',
                'canceled': 'Canceled'
            }
            return statusMap[item.status]
        
        // special case to transform field name into label (mainly used for audit trail foreigns)
        case 'field_name':
            return upperFirst(item.split('_')[0])
        case 'field_value':
            if (type === 'filters') {
                let ret = ''
                if (item?.countries?.length) {
                    ret = 'Countries: ' + item.countries.map(c => c.code).join(', ')
                }

                if (item?.centers?.length) {
                    if (ret) {
                        ret += ' - \r\n'
                    }
                    ret += 'Sites: ' + item.centers.map(c => c.code).join(', ')
                }

                if (item?.central_labs?.length) {
                    if (ret) {
                        ret += ' - \r\n'
                    }
                    ret += 'Central labs: ' + item.central_labs.map(c => c.code).join(', ')
                }
                if (item?.organizations?.length) {
                    if (ret) {
                        ret += ' - \r\n'
                    }
                    ret += 'Organizations: ' + item.organizations.map(c => c.code).join(', ')
                }                                
                return ret
            }
            return item
        default:
            return item?.name ?? item
    }
}