import * as linkify from "linkifyjs"
export default defineNuxtPlugin((nuxtApp) => {
        // const t = nuxtApp.$i18n.t;

        const validators = {
                required: (value) => !!value || 'This field is required',
                checked: value => !!value || 'This is mandatory',

                password: (value) => {
                        const specialChars = "^$%;";
                        if (value.length < 8) return 'Use at least 8 chars'
                        if (!/[0-9]/.test(value)) return 'Use at least a number'
                        if (!/[^a-zA-Z0-9]+/.test(value)) return 'Use at least a special chars';
                        return true;
                },

                confirm: (password) => value => password === value || `Password does not match`,

                email: (value) => (value && linkify.test(value ?? '', "email") || !value) || 'Use a valid e-mail address',
        }

        nuxtApp.provide("valid", validators)
})
