export default defineNuxtPlugin((nuxtApp) => {

    function findNode(root, path_code) {
        for(const node of root.children) {
            if (node.path_code === path_code) return node
            if (node.children.length) {
                const found = findNode(node, path_code)
                if (found) return found
            }
        }
    }
    
    nuxtApp.provide("findNode", findNode)
})
