import WidgetOrganizationStatus from '../../components/widget/OrganizationStatus'

export default {
    "entity": "organization",
    title: 'Organizations',
    upsertTitle: 'Organization',
    sortBy: 'label',
    "headers": [
        {
            "key": "name",
            width: 300,
            size:12,
            "title": "Name",
            "type": "text",
            "table": {
                label: 'Label',
                "sortable": true,
                "filter": true
            },
            "validators": [
                "required"
            ],
            tooltip: item => item?.comment,
            render: item => item.label,
            filter: (search, item) => ([{'label' : `%${search}%`}, {'name' : `%${search}%`}, {'code' : `%${search}%`}, { city: `%${search}%`}])
        },
        {
            "key": "type",
            width: 100,
            "title": "Type",
            "type": "list",
            list: ['CRO', 'Central Laboratory', 'Depot', 'Other', 'Site'],
            "validators": [
                "required"
            ]
        },
        {
            "key": "code",
            "title": "Code",
            "type": "text",
            "table": false,
            "validators": [
                "required"
            ]
        },
        {
            "key": "country_id",
            width: 100,
            name: 'name',
            "type": "autocomplete",
            "subtitle": item => item.code,
            "visible": null,
            "filter": search => ( { name: [{ 'country.name'   : `${search}%`}, {'country.code': `${search}%`}] }),
            "validators": [
                (value, item) => {
                    if (!['Central Laboratory', 'Site'].includes(item.type)) {
                        return true
                    }
                    return !!value || 'This field is required'
                }
            ]            
        },
        {
            "key": "city",
            width: 100,
            "title": "City",
            "type": "text",
            "table": {
                "sortable": true,
                "filter": true
            },
            "validators": [
                (value, item) => {
                    if (!['Central Laboratory', 'Site'].includes(item.type)) {
                        return true
                    }
                    return !!value || 'This field is required'
                }
            ]
        },
        {
            "key": "label",
            size:12,
            "title": "Label",
            "type": "text",
            "validators": [
                "required"
            ],
            "table":false
        },
        {
            "key": "pho.status",
            "label": "Status",
            visible: false,
            "type": "list",
            list: ['Involved', 'Not Involved'],
            filter: search => search === 'Involved' ? ({ 'pho.status': ['Selected', 'Active' ]}): ({ 'pho.status': ['Excluded', 'Closed', null ]}),
            "table": {
                width: 110,
                "sortable": true,
                component: WidgetOrganizationStatus
            },

            "validators": [
                "required"
            ]
        },
        {
            "key": "comment",
            "title": "Comment",
            "type": "textarea",
            "table": false,
            render: item => item?.comment?.substr(0, 10),
            size: 12
        },        
    ],
    "actions": [
        "upsert",
        "remove"
    ]
}