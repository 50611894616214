export default {

    entity: 'organization',
    title: 'site',

    // la lista dei campi da mostrare nella tabella e nel form
    headers: [
        {
            key: 'name',
        },
        {
            key: 'country_id',
            type: 'autocomplete',
            name: 'code'
        },
        // {
        //     key: 'type',
        //     hidden: true,
        //     table: false
        // }
    ],

    // specifico le actions da fare su questa entita'
    // se sono stringhe (upsert o remove) c'e' un comportamento standard
    actions: [
        'upsert', 'remove'
    ]
}