export default defineNuxtPlugin({
    name: 'download',
    dependsOn: ['dialog'],
    setup: (nuxtApp) => {
        async function download(url, loading = () => {}) {
            loading(true)
            const response = await useAPI(url, { raw: true, filters: { export: 'excel' } }).catch(console.error)
            if (!response) {
                nuxtApp.$notify('Error downloading this document', 'warning')
                loading(false)
                return
            }
        
            const filename = response?.headers?.get('content-disposition')?.match(/attachment; filename=\"(.*)\"$/)[1]
            const fileURL = window.URL.createObjectURL(response._data, { oneTimeOnly: true });
        
            const fileLink = document.createElement('a')
            fileLink.href = fileURL
            fileLink.setAttribute('download', filename)
            document.body.appendChild(fileLink)
            fileLink.click()
            fileLink.remove()
            loading(false)
        }
        return { provide: { download } }
    }
})
  