import WidgetPersonnelStatus from '../../components/widget/PersonnelStatus'
import $format from '../../utils/formatter'

export default {
    "entity": "personnel",
    sortBy: 'surname',
    "headers": [
        {
            key: 'name',
            width: 150,
            render: item => `${item?.name} ${item?.surname}`,
            validators: ['required'], // ci sono un po' di validatori di default (required, email)
            filter: search => ({ '_name':[
                { 'name': `%${search}%`},
                { 'surname': `%${search}%`},
            ]}),
            table: {
                label: 'First and Last Name'
            },
            label: 'First Name'
        },
        {
            "key": "surname",
            label: "Last Name",
            "type": "text",
            "table": false,
            "validators": [
                "required"
            ]
        },
        {
            "key": "_type",
            label: 'Type',
            width: 100,
            id: 'organization.type',
            render: item => item.organization.type + ' Staff',
            "type": "list",
            list: ['CRO Staff', 'Central Laboratory Staff', 'Other Staff', 'Site Staff'],
            default: item => item?.organization ? item?.organization?.type + ' Staff' : '',
            readonly: item => !!item.insert_at,
            table: false,
            // "filter": search => ({'organization.type' : search.replace(' Staff', '')})
            // "filter": search => ({'organization.type' : `${search}%`})
        },
        {
            "key": "_organization_type",
            label: 'Organization type',
            width: 100,
            id: 'organization.type',
            render: item => item.organization.type,
            "type": "list",
            visible: false,
            list: ['CRO', 'Central Laboratory', 'Other', 'Site'],
            // visible: false,
            readonly: item => !!item.insert_at,
            "filter": search => ({'organization.type' : search })
        },
        {
            "key": "organization_id",
            width: 300,
            size:12,
            "type": "autocomplete",
            deps: ['_type'],
            filter: (search, item) => ({ "organization.type": item?._type?.replace(' Staff', ''), 'name' : [{'organization.label': `%${search}%`}, {'organization.code': `%${search}%`}, {'organization.name': `%${search}%`}]}),
            table: {
                filter: (search, item) => ([{'organization.label': `%${search}%`}, {'organization.code': `%${search}%`}, {'organization.name': `%${search}%`}]),
            },
            title: item => $format('organization', item, 'title'),
            subtitle: item => $format('organization', item, 'subtitle'), // item => `${item?.code ?? ''} ${item.country.name} - ${item.type}`,
            readonly: item => !!item.insert_at || !item._type,
            "validators": [
                "required"
            ],
            render: item => item.organization.type == 'Site' ? item.organization.label : item.organization.name
        },
        {
            "key": "email",
            width: 100,
            "title": "Email",
            "type": "text",
            "table": {
                "sortable": true,
                "filter": true
            },
            "validators": [
                "email"
            ]
        },
       
        {
            "key": "country.id",
            width: 100,
            "label": "Country",
            name: 'name',
            render: item => item.country.name,
            "type": "hidden",
            "table": {
                "sortable": true,
                "filter": true
            },
            "filter": search => ( { _name: [{ 'country.name'   : `${search}%`}, {'country.code': `${search}%`}] })
        },
        {
            "key": "php.status",
            "label": "Status",
            visible: false,
            "type": "list",
            list: ['In', 'Out'],
            filter: search => search === 'In' ? ({ 'php.status': ['In']}): ({ 'php.status': ['Out', null ]}),
            "table": {
                width: 110,
                "sortable": true,
                component: WidgetPersonnelStatus
            },

            "validators": [
                "required"
            ]
        },
    ],
    "actions": [
        "upsert",
        "remove"
    ]
}